/* Updated Hero.css */

.hero-section {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: #ffffff;
  padding: 60px 0;
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
}

.hero-section h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.hero-section p {
  font-size: 1.1rem;
}

.hero-section form {
  background: rgba(250, 249, 249, 0.85);
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.hero-section .form-control,
.hero-section .form-select {
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  transition: all 0.2s ease-in-out;
}

.hero-section .form-control:focus,
.hero-section .form-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
  background-color: #ffffff;
}

.hero-section .submitButton {
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  background-color: #ff5722;
  border: none;
  border-radius: 50px;
  padding: 15px 40px;
  font-size: 1.1rem;
  transition: transform 0.2s ease, background 0.3s ease;
}

.submitButton:hover {
  transform: translateY(-3px);
  background: linear-gradient(90deg, #ff7043, #d84315);
}

.hero-section .submitButton:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(255, 87, 34, 0.6);
}

.hero-section .submitButton:disabled {
  background-color: #b0bec5;
  cursor: not-allowed;
}

.hero-section .form-placeholder::placeholder {
  color: #6c757d;
  font-style: italic;
}

/* Referral Code */
.hero-section .referral-link {
  color: #007bff;
  font-size: 0.9rem;
  cursor: pointer;
}

.hero-section .referral-link:hover {
  text-decoration: underline;
}

.service-description-container {
  background-color: rgba(250, 249, 249, 0.75); /* Same as form background */
  padding: 5px 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 0.85rem;
  color: #495057;
  margin-top: -5px; /* Attach it closely to the select box */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc ;
}

.selector-with-description select {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  position: relative !important;
}

.selector-with-description .form-control {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: none !important;
}

/* Ensure the wrapping div aligns the description under the select */
.w-100 {
  width: 100%;
}

/* Tooltip Styling */
.tooltip-inner {
  max-width: 300px;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .hero-section {
    padding: 40px 0;
  }
}

@media (max-width: 480px) {
  .hero-section h1 {
    font-size: 1.8rem;
  }
  .hero-section {
    padding: 30px 0;
  }
  .subheading {
    font-size: 1rem;
  }
}

.blackText #referralCodeInput:not(:placeholder-shown)~label{
  color:black;
}

.blueText #referralCodeInput:not(:placeholder-shown)~label{
  color:blue;
}

.redText #referralCodeInput:not(:placeholder-shown)~label{
  color:red;
}

.greenText #referralCodeInput:not(:placeholder-shown)~label{
  color:green;
  font-weight:bolder;
}

.redText{
  color: red !important;
}

.blueText{
  color:blue !important;
}

.redText{
  color: red !important;
}

.greenText{
  color: green !important;
}

.blackText{
  color: black !important;
}

.error-border {
  border-color: red !important;
}

.quantity-error {
  color: red;
  font-size: 0.85rem;
  /* margin-top: -5px; */
  text-align: left;
  font-weight: bold;
}

.bounce {
  display: inline-block;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.burn {
  animation: burn 1.5s infinite alternate;
}

@keyframes burn {
  0% {
    transform: scale(1);
    filter: brightness(100%);
  }
  50% {
    transform: scale(1.1);
    filter: brightness(120%);
  }
  100% {
    transform: scale(1);
    filter: brightness(100%);
  }
}

.nowrap {
  white-space: nowrap;
}


/* Glow effect for urgent text */
.glow {
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.8), 0 0 20px rgba(255, 0, 0, 0.6);
}

.service-description-container {
  display: flex;
  gap: 0.5rem;
  line-height: 1.5;
}

.service-description {
  color: #555; /* Subtle muted color for the description */
}

.service-description-container span {
  color: #333; /* Darker for the quantity */
}

.subheading {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.subheading.fade-out {
  opacity: 0;
  transform: translateY(10px);
}

.subheading.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.customer-love img {
  height: 26px;
}