/* Global Styles */
body, html, #root {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main{
  flex: 1;
}
/* Main Structure */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.platform-icon-small {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  flex-shrink: 0;
}

p {
  font-size: 16px;
  margin: 10px 0;
}