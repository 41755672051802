/* Modern and sleek header styling */
.header-navbar {
  background: linear-gradient(135deg, #6a11cb, #2575fc) !important;
  padding: 10px 30px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  transition: background 0.3s ease !important;
}

.navbar-title {
  font-size: 28px !important;
  font-weight: 700 !important;
  color: #ffffff !important;
}

.navbar .nav-link-item {
  color: #ffffff !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  transition: color 0.3s ease-in-out !important;
}

.navbar .nav-link-item:hover {
  color: #f1c40f !important; /* Brighter color on hover */
}

.header-btn {
  background-color: #ffffff !important;
  color: #6a11cb !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  border-radius: 25px !important;
  padding: 8px 20px !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease !important;
}

.header-btn:hover {
  background-color: #f1c40f !important;
  color: #ffffff !important;
}

.navbar .navbar-collapse {
  justify-content: flex-end !important;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .navbar-title {
    font-size: 24px !important;
  }

  .navbar .nav-link-item {
    margin-left: 10px !important;
    margin-right: 10px !important;
    font-size: 16px !important;
  }

  .header-btn {
    font-size: 16px !important;
    padding: 6px 15px !important;
  }
}

.indexLessthanModals{
  z-index: 999 !important;
}
