/* Payment Form Container */
.payment-section {
  padding: 0px 0;
  margin-top: 0px;
}

/* Stripe Card Element */
.card-element-container {
  padding: 0;
  border-radius: 8px;
  background-color: #f7f7f7;
  border: 2px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
}

.StripeElement {
  width: 100%;
  padding: 10px 12px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #000;
  background-color: #fff;
  border-radius: 8px;
}

.StripeElement--focus {
  border-color: #007bff;
}

/* Payment Request Button (GPay, etc.) */
.payment-request-button {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

.payment-request-button > div {
  width: 100%;
  padding: 0;
}

/* Error Message */
.error-message {
  color: #fa755a;
  text-align: center;
  font-size: 14px;
  margin-bottom: 7px;
}

/* Submit Button */
.submitButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.submitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* General Button Styling */
button {
  transition: background-color 0.3s ease;
}
/* 
button:hover:enabled {
  background-color: #0056b3;
} */
